<template>
  <v-dialog v-model="dialog" @click:outside="closeDialog" scrollable max-width="800px">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="user" class="pt-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="6" sm="6" class="mr-auto py-0">
              <v-text-field
                label="DNI *"
                :disabled="typeForm == 'V'"
                v-model="user.dni"
                :rules="[requiredRules, numericRules, caracteresRules(user.dni, 8)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" class="mr-auto py-0">
              <v-text-field
                label="Username *"
                @input="user.username = user.username.toUpperCase()"
                :disabled="typeForm == 'V'"
                v-model="user.username"
                :rules="[requiredRules, minMaxRules(user.fullName, 6, 100)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" class="mr-auto py-0 d-flex">
              <v-switch v-if="typeForm == 'E'" v-model="user.updatePassword"></v-switch>
              <v-text-field
                label="Password *"
                type="password"
                :rules="
                  typeForm == 'N' || (typeForm == 'E' && user.updatePassword)
                    ? [requiredRules, minMaxRules(user.password, 3, null)]
                    : []
                "
                :disabled="typeForm == 'V' || (typeForm == 'E' && !user.updatePassword)"
                v-model="user.password"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" class="mr-auto py-0 d-flex">
              <v-text-field
                label="Confirmar Password *"
                type="password"
                :rules="
                  typeForm == 'N' || (typeForm == 'E' && user.updatePassword)
                    ? [
                        requiredRules,
                        minMaxRules(user.confirmPassword, 3, null),
                        confirmPasswordRules(user.password, user.confirmPassword)
                      ]
                    : []
                "
                :disabled="typeForm == 'V' || (typeForm == 'E' && !user.updatePassword)"
                v-model="user.confirmPassword"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mr-auto py-0">
              <v-text-field
                label="Nombre Completo *"
                @input="user.fullName = user.fullName.toUpperCase()"
                :disabled="typeForm == 'V'"
                v-model="user.fullName"
                :rules="[requiredRules, minMaxRules(user.fullName, 3, 100)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" class="mr-auto py-0">
              <v-text-field
                label="Email *"
                :disabled="typeForm == 'V'"
                v-model="user.email"
                :rules="[requiredRules, emailRules]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" class="mr-auto py-0">
              <v-select
                label="Posición *"
                v-model="userPosition"
                :disabled="typeForm == 'V'"
                :rules="[requiredRules]"
                :items="userPositions"
                item-text="text"
                item-value="id"
                return-object
              ></v-select>
            </v-col>

            <v-col cols="12" md="6" sm="6" class="mr-auto py-0">
              <v-select
                label="Empresa *"
                v-model="company"
                :disabled="typeForm == 'V'"
                :rules="[requiredRules]"
                :items="empresas"
                item-text="text"
                item-value="id"
                return-object
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error" text @click="closeDialog">Cerrar</v-btn>
        <v-btn v-if="typeForm != 'V'" color="light" text @click="save">Guardar</v-btn>
      </v-card-actions>
    </v-card>

    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </v-dialog>
</template>

<script>
import UsuarioService from '../services/UsuarioService';
import Swal from 'sweetalert2';

export default {
  name: 'FormularioUsuario',
  components: {},
  computed: {},
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          userId: '',
          dni: '',
          username: '',
          password: '',
          confirmPassword: '',
          fullName: '',
          email: '',
          userPositionId: '',
          companyId: '',
          databaseId: '',
          updatePassword: false
        };
      }
    },
    dialog: {
      type: Boolean,
      default: false
    },
    typeForm: {
      type: String,
      default: 'N'
    }
  },
  data: () => ({
    typesQuestion: [],
    userPositions: [],
    empresas: [],
    company: '',
    userPosition: '',
    auxCompany: '',
    auxDatabase: '',
    service: null,
    showLoading: false,
    valid: false,
    edit: false,
    mostrarFormato: false,
    isView: null,
    title: '',
    requiredRules: (v) => !!v || 'El campo es obligatoria',
    emailRules: (v) => /.+@.+\..+/.test(v) || 'E-mail inválido',
    minMaxRules: (value, min, max) => {
      if (value)
        return (
          (value.length >= min && (value.length <= max || max == null)) ||
          `Min: ${min} ${max != null ? '- Max: ' + max : ''} caract.`
        );
      else return true;
    },
    caracteresRules: (value, num) => {
      if (value) return value.length == num || `Campo debe tener ${num} caract.`;
      else return true;
    },
    numericRules: (value) => {
      const pattern = /^[0-9]+$/;
      return pattern.test(value) || 'El campo debe ser numérico';
    },
    confirmPasswordRules: (password, confirmPassword) => {
      return password == confirmPassword || `Password no coinciden`;
    }
  }),
  watch: {
    async dialog(val) {
      if (val) {
        this.title = this.typeForm == 'N' ? 'Nuevo Usuario' : this.typeForm == 'E' ? 'Editar Usuario' : 'Ver Usuario';
        if (this.$refs.form) await this.$refs.form.resetValidation();
      }
    },
    async userPosition(newValue) {
      this.user.companyId = '';
      this.company = '';
      this.user.userPositionId = newValue.userPositionId;
    },
    async company(newValue) {
      this.user.companyId = newValue.company_id;
      this.user.databaseId = newValue.database_id;
    },
    async typeForm(val) {
      if (val == 'E' || val == 'V') {
        this.auxCompany = this.user.companyId;
        this.auxDatabase = this.user.databaseId;
        await this.asignarPosition();
        await this.asignarCompany();
      }
    }
  },
  methods: {
    async asignarPosition() {
      this.userPosition = this.userPositions.filter((el) => el.userPositionId == this.user.userPositionId)[0];
      console.log(this.userPosition);
    },
    async asignarCompany() {
      console.log(this.user);
      this.company = this.empresas.filter(
        (el) => el.company_id == this.auxCompany && el.database_id == this.auxDatabase
      )[0];
    },
    async initialize() {
      this.showLoading = true;
      const res = await this.service.get().execResource('listarUsuariosPosiciones');
      if (res.status) {
        this.userPositions = res.data.map((el) => {
          return {
            ...el,
            id: el.userPositionId,
            text: el.description
          };
        });
      }
      this.showLoading = false;
    },
    async listEmpresas() {
      this.showLoading = true;
      const res = await this.service.get().execResource('listarEmpresas');
      if (res.status) {
        this.empresas = res.data.map((el) => {
          return {
            ...el,
            id: el.company_id + '_' + el.database_id,
            text: el.company_id + ' - ' + el.business_name + ' - ' + el.database_id
          };
        });
      }
      this.showLoading = false;
    },
    async save() {
      if (this.$refs.form.validate(true)) {
        this.messageFormError = '';
        let question = '¿Desea guardar los datos?';
        if (this.edit) question = '¿Desea actualizar los datos?';

        const { isConfirmed } = await this.alertDialogYesNo('question', question);

        if (isConfirmed) {
          if (this.typeForm == 'E') {
            const res = await this.service.put().execResource('actualizarUsuario', {
              user: this.user
            });
            if (res.status) {
              await this.alertDialog('success', res.data);
              this.closeDialog();
              this.$emit('listar');
            } else {
              await this.alertDialog('error', res.data);
            }
          } else {
            const res = await this.service.post().execResource('guardarUsuario', {
              user: this.user
            });
            if (res.status) {
              await this.alertDialog('success', res.data);
              this.closeDialog();
              this.$emit('listar');
            } else {
              await this.alertDialog('error', res.data);
            }
          }
        }
      }
    },
    async alertDialogYesNo(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: `Cancelar`,
        confirmButtonText: `Guardar`,
        reverseButtons: true
      });
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    llenarCampos(item) {
      console.log(item);
    },
    closeDialog() {
      this.userPosition = '';
      this.companyId = '';
      this.$emit('closeDialog');
    }
  },
  async created() {
    this.service = this.$httpService(new UsuarioService(), this);
    await this.initialize();
    await this.listEmpresas();
  }
};
</script>
