class UsuarioService {
  constructor() {}
  resources = () => ({
    guardarUsuario: {
      uri: `/v1/user`,
      method: ['post']
    },
    actualizarUsuario: {
      uri: `/v1/user`,
      method: ['put']
    },
    listarUsuarios: {
      uri: `/v1/listuser`,
      method: ['get']
    },
    cambiarEstadoUsuario: {
      uri: `/v1/stateuser`,
      method: ['put']
    },
    listarUsuariosPosiciones: {
      uri: `/v1/listuserposition`,
      method: ['get']
    },
    listarEmpresas: {
      uri: `/v1/listcompany`,
      method: ['get']
    }
  });
}
export default UsuarioService;
