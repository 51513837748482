<template>
  <div>
    <BaseBreadcrumb :title="'Matriz Usuarios'">
      <!--<template v-slot:options-right>
        <v-btn color="primary" small v-on:click="nuevo">Nuevo</v-btn>
      </template>-->
    </BaseBreadcrumb>
    <div style="padding-top: 55px;">
      <v-card class="elevation-4 px-5 py-5">
        <v-row>
          <v-col cols="12" sm="12">
            <v-data-table
              :headers="headers"
              :items="body"
              :height="430"
              dense
              no-results-text="No se encontraron datos"
              no-data-text="No se encontraron datos"
              :search="search"
              class="border"
              :hide-default-footer="true"
              disable-pagination
              :fixed-header="true"
            >
              <template v-slot:top>
                <div class="w-100 d-flex justify-space-between align-center pa-2">
                  <div class="black--text pt-1">USUARIOS</div>
                  <v-divider class="mx-4" vertical></v-divider>
                  <v-text-field
                    v-model="search"
                    dense
                    append-icon="mdi-magnify"
                    label="Buscar"
                    class="mt-0 text-field-search-matriz"
                    background-color="rgb(220, 220, 220, 0.16)"
                    rounded
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-divider class="mx-4" vertical></v-divider>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" fab dark x-small @click="newItem" v-bind="attrs" v-on="on">
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Nuevo</span>
                  </v-tooltip>
                </div>
                <v-divider></v-divider>
              </template>
              <template v-slot:[`item.isGlobal`]="{ item }">
                {{ item.isGlobal == 0 ? 'Por Empresa' : 'Por Coorporación' }}
              </template>
              <template v-slot:[`item.accion`]="{ item }">
                <div class="d-flex justify-start pa-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab icon x-small color="primary" v-bind="attrs" v-on="on" @click="editItem(item)">
                        <v-icon small dark>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        icon
                        x-small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        v-on:click="updateStatusCampaign(item)"
                      >
                        <v-icon small dark>
                          {{ item.active == 1 ? 'mdi-delete' : 'mdi-delete-off' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ item.active == 1 ? 'Dar de Baja' : 'Activar' }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab icon x-small color="success" v-bind="attrs" v-on="on" @click="viewItem(item)">
                        <v-icon small dark>
                          mdi-eye
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Encuesta</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:[`item.active`]="{ item }">
                <v-icon :color="item.active == 1 ? 'green' : 'red darken-2'" small>
                  {{ item.active == 1 ? 'mdi-check' : 'mdi-close' }}
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <formulario-usuario
          :dialog="dialogForm"
          :user="itemSeleccionado"
          :typeForm="typeForm"
          @closeDialog="closeDialog()"
          @listar="initialize()"
        ></formulario-usuario>
      </v-card>

      <div v-if="showLoading" class="progress-background">
        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import UsuarioService from '../services/UsuarioService';
import FormularioUsuario from '../components/FormularioUsuario.vue';
import Swal from 'sweetalert2';
import moment from 'moment';

export default {
  components: {
    FormularioUsuario
  },
  name: 'MatrizUsuario',
  data: () => ({
    headers: [
      { text: 'ACCIONES', value: 'accion', align: 'center', class: 'grey lighten-3' },
      { text: 'Empresa', value: 'businessName', validateStatus: false, class: 'grey lighten-3' },
      { text: 'Usuario', value: 'username', validateStatus: false, class: 'grey lighten-3' },
      { text: 'DNI', value: 'dni', validateStatus: false, class: 'grey lighten-3' },
      { text: 'Nombre Completo', value: 'fullName', validateStatus: false, class: 'grey lighten-3' },
      { text: 'Email', value: 'email', validateStatus: false, class: 'grey lighten-3' },
      { text: 'Activo', value: 'active', validateStatus: false, class: 'grey lighten-3' }
    ],
    body: [],
    service: null,
    itemSeleccionado: null,
    dialog: false,
    showLoading: false,
    dialogForm: false,
    dialogReporte: false,
    search: '',
    typeForm: ''
  }),
  methods: {
    async initialize() {
      this.showLoading = true;
      const res = await this.service.get().execResource('listarUsuarios', { type: '' });
      if (res.status) {
        this.body = res.data.map((el) => {
          return {
            ...el,
            startPeriod: moment(el.startPeriod).format('YYYY-MM-DD'),
            endPeriod: moment(el.endPeriod).format('YYYY-MM-DD')
          };
        });
      }
      this.showLoading = false;
    },
    newItem() {
      this.dialogForm = true;
      this.typeForm = 'N';
      this.itemSeleccionado = {
        userId: '',
        dni: '',
        username: '',
        password: '',
        confirmPassword: '',
        fullName: '',
        email: '',
        userPositionId: '',
        companyId: '',
        databaseId: '',
        updatePassword: false
      };
    },
    editItem(item) {
      this.dialogForm = true;
      this.typeForm = 'E';
      this.itemSeleccionado = { ...item, updatePassword: false, password: '', confirmPassword: '' };
    },
    viewItem(item) {
      this.dialogForm = true;
      this.typeForm = 'V';
      this.itemSeleccionado = item;
      //this.$router.push({ path: `formato_encuesta/${item.id}`, query: { isView: true } });
    },
    viewReport(item) {
      this.dialogReporte = true;
      this.itemSeleccionado = item;
    },
    async updateStatusCampaign(item) {
      const { isConfirmed } = await this.alertDialogYesNo('question', '¿Desea eliminar la usuario?');

      if (isConfirmed) {
        const res = await this.service.put().execResource('cambiarEstadoUsuario', {
          user_id: item.userId,
          type: item.active == 1 ? 0 : 1
        });

        if (res.status) {
          await this.alertDialog('success', res.data);
          this.initialize();
        } else {
          await this.alertDialog('error', res.data);
        }
      }
    },
    async alertDialogYesNo(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: `Cancelar`,
        confirmButtonText: `Aceptar`,
        reverseButtons: true
      });
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    duplicateItem(item) {
      //localStorage.setItem('idQuizDuplicate', item.id);
      //this.$router.push({ name: 'formato_encuesta' });
      this.$router.push({ path: `formato_encuesta`, query: { idDuplicate: item.id } });
    },
    assignQuiz(item) {
      this.dialog = true;
      this.itemSeleccionado = item;
    },
    async closeDialog() {
      this.typeForm = 'N';
      this.dialogForm = false;
    }
  },
  created() {
    this.service = this.$httpService(new UsuarioService(), this);
    this.initialize();
  }
};
</script>

<style>
/*.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  color: white !important;
}*/
</style>
